import React, { useReducer, useState, useEffect } from "react";
import { AgentContext, TicketContext } from "./index";
import { AgentReducer } from "./reducer";
import { types } from "../types";
import { useSocket } from "../hooks/useSocket";
import { environment } from "../config";
import { Subject, takeUntil } from "rxjs";
import { contactSrc } from "../services/contact.service";

const init = () => {
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : undefined;
  const groups = localStorage.getItem("groups")
    ? JSON.parse(localStorage.getItem("groups"))
    : [];
  const abilities = localStorage.getItem("abilities")
    ? JSON.parse(localStorage.getItem("abilities"))
    : [];
  const chatList = localStorage.getItem("chatList")
    ? JSON.parse(localStorage.getItem("chatList"))
    : [];
  const currentChat =
    localStorage.getItem("currentChat") &&
    localStorage.getItem("currentChat") !== "undefined"
      ? JSON.parse(localStorage.getItem("currentChat"))
      : undefined;
  const queue = localStorage.getItem("queue")
    ? JSON.parse(localStorage.getItem("queue"))
    : [];
  const messages = localStorage.getItem("messages")
    ? JSON.parse(localStorage.getItem("messages"))
    : [];

  return {
    user,
    isOpen: false,
    isOpenDialog: false,
    groups,
    abilities,
    isLoading: false,
    chatList,
    currentChat,
    queue,
    configDialog: {
      open: false,
      type: undefined,
    },
    snackbar: {
      open: false,
      message: undefined,
      severity: undefined,
    },
    messages,
    agents: [],
    currentFiles: [],
    currentAbilities: [],
    isTabActive: true,
    isPreviewImages: false,
    messagesWithImages: [],
    selectedImage: undefined,
    search: null,
    filterHistory: "",
    filterHistoryItems: [
      {
        id: 0,
        title: "Canales",
        items: [
          { name: "Facebook", value: "facebook" },
          { name: "Instagram", value: "instagram" },
          { name: "WhatsApp", value: "whatsapp" },
          // { name: "Telegram", value: "telegram" },
          { name: "Web", value: "web" },
          { name: "Widget", value: "web-widget" },
        ],
      },
      {
        id: 1,
        title: "Estatus",

        items: [
          { name: "Resuelto", value: "resolved" },
          { name: "No resuelto", value: "notResolved" },
        ],
      },
    ],
    filterChecked: [],
    history: [],
    openDialogDetails: false,
    historyDetails: {
      messages: [],
      files: [],
      details: undefined,
    },
    historyDetailsScreen: "general",
    logged: localStorage.getItem("logged") ? true : false,
  };
};

export const AgentProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AgentReducer, {}, init);
  const { socket, connect, connectSocket, setNotified_connect } = useSocket(
    environment.ws_url,
    state.user
  );
  const { logged } = state;
  useEffect(() => {
    if (logged) connectSocket();
  }, [logged]);

  useEffect(() => {
    if (!logged) {
      socket?.disconnect();
      init();
    }
  }, [logged]);

  /* useEffect(() => {
    console.log(localStorage.getItem("connect"));
    if (localStorage.getItem("connect") === "false") socket?.disconnect();
  }, [socket]);*/

  const setUser = (user) => {
    dispatch({
      type: types.sign_in,
      payload: user,
    });
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("logged", "true");
  };

  const onLogout = () => {
    dispatch({
      type: types.logout,
      payload: undefined,
    });

    if (connect) {
      socket.emit("disconnect_agent");
      setNotified_connect(false);
    }
    localStorage.clear();
  };

  const handleSidebar = (event) => {
    dispatch({
      type: types.handle_sidebar,
      payload: event,
    });

    localStorage.setItem("isOpen", event);
  };

  const handleDialogClose = (event) => {
    dispatch({
      type: types.handle_dialog_close,
      payload: event,
    });

    localStorage.setItem("isOpenDialog", event);
  };

  const setGroups = (groups) => {
    dispatch({
      type: types.get_groups,
      payload: groups,
    });

    localStorage.setItem("groups", JSON.stringify(groups));
  };

  const setCurrentChat = (chat) => {
    dispatch({
      type: types.get_current_chat,
      payload: chat,
    });
    localStorage.setItem("currentChat", JSON.stringify(chat));
    if (chat === undefined) {
      localStorage.removeItem("videolink_chat");
    }
  };

  const setLoading = (event) => {
    dispatch({
      type: types.set_loading,
      payload: event,
    });
  };

  const setChatList = (list) => {
    dispatch({
      type: types.set_chat_list,
      payload: list,
    });

    localStorage.setItem("chatList", JSON.stringify(list));
  };

  const setQueue = (queue) => {
    dispatch({
      type: types.get_queue,
      payload: queue,
    });

    localStorage.setItem("queue", JSON.stringify(queue));
  };

  const setConfigDialog = (config) => {
    dispatch({
      type: types.set_config_dialog,
      payload: config,
    });
  };

  const setSnackbar = (snackbar) => {
    dispatch({
      type: types.set_snackbar,
      payload: snackbar,
    });
  };

  const setLocalMessages = (messages) => {
    dispatch({
      type: types.set_messages,
      payload: messages,
    });
    localStorage.setItem("messages", JSON.stringify(messages));
  };

  const setAbilities = (abilities) => {
    dispatch({
      type: types.get_abilities,
      payload: abilities,
    });

    localStorage.setItem("abilities", JSON.stringify(abilities));
  };

  const setAgents = (agents) => {
    dispatch({
      type: types.get_agents,
      payload: agents,
    });
  };

  const setTabActive = (event) => {
    dispatch({
      type: types.set_tab_active,
      payload: event,
    });
    localStorage.setItem("tabActive", event);
  };

  const setCurrentFiles = (files) => {
    dispatch({
      type: types.set_current_files,
      payload: files,
    });
  };

  const setCurrentAbilities = (abilities) => {
    dispatch({
      type: types.set_current_abilities,
      payload: abilities,
    });
  };

  const setIsPreviewImages = (event) => {
    dispatch({
      type: types.set_preview_images,
      payload: event,
    });
  };

  const setMessagesWithImages = (messages) => {
    dispatch({
      type: types.set_messages_images,
      payload: messages,
    });
  };

  const setSelectedImage = (image) => {
    dispatch({
      type: types.set_selected_image,
      payload: image,
    });
  };

  const setSearch = (search) => {
    dispatch({
      type: types.set_search,
      payload: search,
    });
  };

  const setFilterHistory = (filter) => {
    dispatch({
      type: types.set_filter_history,
      payload: filter,
    });
  };

  const setFilterChecked = (filter) => {
    dispatch({
      type: types.set_checked_filter,
      payload: filter,
    });
  };

  const setHistory = (history) => {
    dispatch({
      type: types.get_history,
      payload: history,
    });
  };

  const handleDialogDetails = (event) => {
    dispatch({
      type: types.handle_dialog_details,
      payload: event,
    });
  };

  const handleDetails = (details) => {
    dispatch({
      type: types.handle_details,
      payload: details,
    });
  };

  const handleDetailsScreen = (screen) => {
    dispatch({
      type: types.handle_dialog_screen,
      payload: screen,
    });
  };

  const _unSuscribeAll = new Subject();

  const [listContact, setlistContact] = useState([]);

  useEffect(() => {
    contactSrc.contacts
      .pipe(takeUntil(_unSuscribeAll))
      .subscribe((newValue) => {
        setlistContact(newValue);
      });

    return () => {
      _unSuscribeAll.next(true);
      _unSuscribeAll.complete();
    };
  }, []);

  return (
    <AgentContext.Provider
      value={{
        state: state,
        setUser: setUser,
        handleSidebar: handleSidebar,
        onLogout: onLogout,
        handleDialogClose: handleDialogClose,
        setGroups: setGroups,
        socket: socket,
        connect: connect,
        setCurrentChat: setCurrentChat,
        setLoading: setLoading,
        setChatList: setChatList,
        setQueue: setQueue,
        setConfigDialog: setConfigDialog,
        setSnackbar: setSnackbar,
        setLocalMessages: setLocalMessages,
        setAbilities: setAbilities,
        setAgents: setAgents,
        setTabActive: setTabActive,
        setCurrentFiles: setCurrentFiles,
        setCurrentAbilities: setCurrentAbilities,
        setIsPreviewImages: setIsPreviewImages,
        setMessagesWithImages: setMessagesWithImages,
        setSelectedImage: setSelectedImage,
        setSearch: setSearch,
        setFilterHistory: setFilterHistory,
        setFilterChecked: setFilterChecked,
        setHistory: setHistory,
        handleDialogDetails: handleDialogDetails,
        handleDetails: handleDetails,
        handleDetailsScreen: handleDetailsScreen,
        connectSocket,
        listContact,
        setlistContact,
      }}
    >
      {children}
    </AgentContext.Provider>
  );
};

export const TicketProvider = ({ children }) => {
  const [ticket, setTicket] = useState({
    _id: "",
    name: "",
    number: 0,
    summary: "",
    description: "",
    status: "open",
    priority: false,
    date: "",
    isTransfer: false,
  });
  const [status, setStatus] = useState({
    open: false,
    icon: " ",
    text: "Seleccione",
    value: "",
  });
  return (
    <TicketContext.Provider
      value={{
        ticket,
        setTicket,
        status,
        setStatus,
      }}
    >
      {children}
    </TicketContext.Provider>
  );
};
