import { useState, useContext, useEffect, forwardRef } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

import DesktopView from "../views/chat/desktop";
import MobileView from "../views/chat/mobile";

import { AgentContext } from "../context";
import DialogClose from "../components/dialogClose";
import service from "../service";

import DialogTransferAtention from "../components/dialogTransferAtention";
import ImgView from "../components/imgView";
import { ChatContextProvider } from "../context/chatContext";

const Chat = ({
  isMobile,
  width,
  isLoading,
  setIsLoading,
  cleanInput,
  setCleanInput,
}) => {
  const {
    setUser,
    state,
    handleSidebar,
    onLogout,
    handleDialogClose,
    setGroups,
    setAbilities,
    setCurrentChat,
    socket,
    setChatList,
    setConfigDialog,
    setSnackbar,
    setLocalMessages,
    setAgents,
    connect,
    setCurrentFiles,
    setMessagesWithImages,
    setSelectedImage,
    setIsPreviewImages,
    setNotified_connect,
  } = useContext(AgentContext);
  const container =
    window !== undefined ? () => window.document.body : undefined;
  const navigate = useNavigate();
  const [height, setHeight] = useState(window.innerHeight);
  const [loading, setLoading] = useState(false);
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);
  const [contactsInfo, setContactsInfo] = useState([]);
  const { chatList, currentChat } = state;
  const [modeCloseDialog, setModeCloseDialog] = useState(true);
  const [dataContact, setDataContact] = useState(null);
  const [contactsMatch, setContactsMatch] = useState([]);

  const init = async (showLoading = true) => {
    try {
      if (showLoading) setLoading(true);

      if (
        (localStorage.getItem("chatList") &&
          JSON.parse(localStorage.getItem("chatList").length === 0)) ||
        !localStorage.getItem("chatList")
      ) {
        const progressChat =
          state && state.user ? await service.getProgressChat() : [];

        setChatList(progressChat);
      }
      const groups =
        state && state.user ? await service.getGroups(state.user.company) : [];
      const abilities =
        state && state.user
          ? await service.getAbilities(state.user.company)
          : [];
      const agents =
        state && state.user
          ? await service.getAllAgents(state.user.company)
          : [];
      const agentFilterData = agents.map((agent) => ({
        id: agent._id,
        fullName: `${agent.firstname} ${agent.lastname}`,
      }));
      const preferences = await service.getPreferences(state.user.company);

      if (preferences.noForm) delete preferences.attentionsForms;
      setUser({ ...state.user, preferences });
      localStorage.setItem("sound", state.user.isSoundActive);
      setGroups(groups);
      setAbilities(abilities);
      setAgents(agentFilterData);

      if (localStorage.getItem("videolink_chat")) {
        const currentChat = await service.getChat(
          state.user.company,
          localStorage.getItem("videolink_chat")
        );
        if (currentChat.agent === state.user._id) {
          const localMessages = currentChat.totalMessages
            ? currentChat.totalMessages
                .filter(
                  (eventChat) =>
                    eventChat.eventType === "msg" ||
                    (eventChat.eventType === "sysmsg" &&
                      (eventChat.action === "chat_transfer" ||
                        eventChat.action === "chat_ended" ||
                        eventChat.action === "chat_ended_admin"))
                )
                .sort(function (a, b) {
                  return new Date(a.date) - new Date(b.date);
                })
            : currentChat.history
                .filter(
                  (eventChat) =>
                    eventChat.eventType === "msg" ||
                    (eventChat.eventType === "sysmsg" &&
                      (eventChat.action === "chat_transfer" ||
                        eventChat.action === "chat_ended" ||
                        eventChat.action === "chat_ended_admin"))
                )
                .sort(function (a, b) {
                  return new Date(a.date) - new Date(b.date);
                });
          Promise.all(
            localMessages?.map(
              async (message) =>
                (message.senderName = await service.getAgent(
                  state.user.company,
                  message.sender
                ))
            )
          );
          if (currentChat.status === "progress") {
            setCurrentChat(currentChat);
            setLocalMessages(localMessages);
            localStorage.setItem("messages", JSON.stringify(localMessages));
            const currentFiles = await service.getCurrentFiles(
              state.user.company,
              localStorage.getItem("videolink_chat")
            );
            setCurrentFiles(currentFiles);
          } else {
            setCurrentChat(undefined);
            setChatList(
              state.chatList?.filter(
                (chat) => chat._id !== localStorage.removeItem("videolink_chat")
              )
            );
            setLocalMessages([]);
            localStorage.setItem("messages", JSON.stringify([]));
            localStorage.removeItem("videolink_chat");
          }
        }

        if (currentChat?.contact) {
          const contactsMath = await service.getContactsMatch(
            currentChat?.contact
          );
          setContactsMatch(contactsMath?.result);
        }
      }
      await getContactsInfo();
      if (showLoading) setLoading(false);
    } catch (err) {
      if (showLoading) setLoading(false);
    }
    setTimeout(() => {
      service.scrollToBottom();
    }, 100);
  };

  const getContactsInfo = async () => {
    try {
      const contactsInfo = await service.getContactsInfo();
      // console.log({ contactsInfo });
      const formattedData = contactsInfo.contacts
        .filter((el) => el._id !== currentChat?.contact?._id)
        .map((obj) => {
          for (const key in obj) {
            if (obj.hasOwnProperty(key) && obj[key] === "No especificado") {
              obj[key] = "";
            }
          }
          return obj;
        });

      setContactsInfo(formattedData);
    } catch (err) {
      console.log(err);
    }
  };

  const getMoreMessages = async () => {
    setLoadingMoreMessages(true);
    try {
      const chatDB = await service.getChat(
        state.user.company,
        localStorage.getItem("videolink_chat"),
        state.currentChat.currentAttentions
      );
      const currentChat = { ...state.currentChat };
      currentChat.currentAttentions =
        state.currentChat.currentAttentions + chatDB.currentAttentions;
      const totalMessages = currentChat.totalMessages.concat(
        chatDB.totalMessages
      );
      currentChat.isNext = chatDB.isNext;
      currentChat.totalMessages = totalMessages;
      if (currentChat.agent === state.user._id) {
        const localMessages = currentChat.totalMessages
          ? currentChat.totalMessages
              .filter(
                (eventChat) =>
                  eventChat.eventType === "msg" ||
                  (eventChat.eventType === "sysmsg" &&
                    (eventChat.action === "chat_transfer" ||
                      eventChat.action === "chat_ended" ||
                      eventChat.action === "chat_ended_admin"))
              )
              .sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
              })
          : currentChat.history
              .filter(
                (eventChat) =>
                  eventChat.eventType === "msg" ||
                  (eventChat.eventType === "sysmsg" &&
                    (eventChat.action === "chat_transfer" ||
                      eventChat.action === "chat_ended" ||
                      eventChat.action === "chat_ended_admin"))
              )
              .sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
              });
        Promise.all(
          localMessages?.map(
            async (message) =>
              (message.senderName = await service.getAgent(
                state.user.company,
                message.sender
              ))
          )
        );
        const uniqueMessages = {};

        localMessages.forEach((message) => {
          const { _id } = message;
          if (!uniqueMessages[_id]) {
            uniqueMessages[_id] = message;
          }
        });

        // Convertir los valores del objeto a un nuevo arreglo
        const cleanMessages = Object.values(uniqueMessages);
        setCurrentChat(currentChat);
        setLocalMessages(cleanMessages);
        localStorage.setItem("messages", JSON.stringify(cleanMessages));
        const currentFiles = await service.getCurrentFiles(
          state.user.company,
          localStorage.getItem("videolink_chat")
        );
        setCurrentFiles(currentFiles);
      }
    } catch (error) {
      console.log(error);
    }
    setLoadingMoreMessages(false);
  };

  const onChatEnd = (type, reason) => {
    socket?.emit("chat_ended", {
      chat_id: state.currentChat._id,
      reason: {
        type,
        reason,
      },
    });
    localStorage.removeItem("videolink_chat");
    setCurrentChat(undefined);
    setChatList(
      state.chatList?.filter((chat) => chat._id !== state.currentChat._id)
    );
    setLocalMessages([]);
    localStorage.setItem("messages", JSON.stringify([]));
  };

  const onUpdateClientInfo = async (id, form, chatId, formUpdate) => {
    try {
      const updateGuest = await service.onUpdateClientInfo(
        state.user.company,
        id,
        form
      );
      const customFieldsService = await service.onUpdateDynamicForm(
        chatId,
        formUpdate
      );
      const { customFields } = customFieldsService;

      const { currentChat, chatList } = service.updateCurrentChat(
        state.currentChat,
        state.chatList,
        updateGuest,
        customFields
      );

      setChatList(chatList);
      setCurrentChat(currentChat);
      setConfigDialog({
        open: true,
        type: "update",
      });
    } catch (err) {
      console.log(err);

      setSnackbar({
        open: true,
        message: "La información no se pudo actualizar",
        severity: "error",
      });
    }
  };

  const onUpdateDynamicForm = (id, form) => {
    service
      .onUpdateDynamicForm(id, form)
      .then((res) => {
        const { customFields } = res;
        setCurrentChat({
          ...state.currentChat,
          customFields,
        });
      })
      .catch((err) => {
        setSnackbar({
          open: true,
          message: "La información no se pudo actualizar",
          severity: "error",
        });
      });
  };

  const onSendMessage = (message, formatMessage) => {
    const newMessages = state.messages;
    newMessages.push(formatMessage);
    setLocalMessages(newMessages);
    socket?.emit("message", {
      cont: message,
      type: typeof message === "string" ? "text" : "file",
      chat: state.currentChat._id,
      id: service.randomStringGenerator(), // evitar la duplicacion de mensajes
    });
    setTimeout(() => {
      service.scrollToBottom();
    }, 100);
  };

  const onUploadImageMessage = (file) => {
    return service.onUploadImageMessage(file);
  };

  const getCurrentFiles = async (chatId) => {
    try {
      const currentFiles = await service.getCurrentFiles(
        state.user.company,
        chatId
      );
      setCurrentFiles(currentFiles);
    } catch (err) {
      console.log(err);
    }
  };

  const getSession = async () => {
    try {
      const session = await service.onSession(state.user.email);
    } catch (err) {
      if (err.dataResponse.msg === "session/duplicate") localStorage.clear();
      console.log(err);
    }
  };

  const getCurrentChat = async (chatId, chat) => {
    // setLoading(true);
    setIsLoading(true);
    try {
      setCurrentChat(chat);
      setCleanInput(!cleanInput);
      const currentChat = await service.getChat(state.user.company, chatId);
      localStorage.setItem("videolink_chat", currentChat._id);
      const localMessages = currentChat.totalMessages
        ? currentChat.totalMessages
            .filter(
              (eventChat) =>
                eventChat.eventType === "msg" ||
                (eventChat.eventType === "sysmsg" &&
                  (eventChat.action === "chat_transfer" ||
                    eventChat.action === "chat_ended" ||
                    eventChat.action === "chat_ended_admin"))
            )
            .sort(function (a, b) {
              return new Date(a.date) - new Date(b.date);
            })
        : currentChat.history
            .filter(
              (eventChat) =>
                eventChat.eventType === "msg" ||
                (eventChat.eventType === "sysmsg" &&
                  (eventChat.action === "chat_transfer" ||
                    eventChat.action === "chat_ended" ||
                    eventChat.action === "chat_ended_admin"))
            )
            .sort(function (a, b) {
              return new Date(a.date) - new Date(b.date);
            });

      Promise.all(
        localMessages?.map(
          async (message) =>
            (message.senderName = await service.getAgent(
              state.user.company,
              message.sender
            ))
        )
      );

      state.chatList.find((chat) => chat._id === chatId).count = 0;

      state.chatList.find((chat) => chat._id === chatId).contact =
        currentChat.contact ? currentChat.contact : undefined;
      if (currentChat?.contact) {
        const contactsMath = await service.getContactsMatch(
          currentChat?.contact
        );
        setContactsMatch(contactsMath?.result);
      }
      setCurrentChat(currentChat);

      setLocalMessages(localMessages);
      setChatList(state.chatList);

      const contactsInfo = await service.getContactsInfo();
      if (contactsInfo?.contacts && currentChat?.contact) {
        const formattedData = contactsInfo.contacts
          .filter((el) => el._id !== currentChat.contact._id)
          .map((obj) => {
            for (const key in obj) {
              if (obj.hasOwnProperty(key) && obj[key] === "No especificado") {
                obj[key] = "";
              }
            }
            return obj;
          });
        setContactsInfo(formattedData);
      }

      setTimeout(() => {
        service.scrollToBottom();
      }, 100);
      setLoading(false);
      setIsLoading(false);
    } catch (err) {
      if (!connect) localStorage.setItem("videolink_chat", chatId);
      console.log(err);
      // setLoading(false);
      setIsLoading(false);
    }
  };

  const handleAgentConnect = (event) => {
    if (event) {
      socket?.connect();
    } else {
      socket?.disconnect();
      socket?.emit("disconnect_agent");
      setNotified_connect(false);
    }
    localStorage.setItem("connect", event);
  };

  const getCurrentAbilities = async (group) => {
    socket?.emit("group:abilities:active", { group });
    const abilities = await service.getAbilities(state.user.company);
    setAbilities(abilities);
  };

  const onTransferCall = (group, abilitie, reason) => {
    socket?.emit("chat_transfer", {
      chat_id: localStorage.getItem("videolink_chat"),
      group,
      ability: abilitie,
      reason,
    });
    setChatList(
      state.chatList.filter((chat) => chat._id !== state.currentChat._id)
    );
    setLocalMessages([]);
    setCurrentChat(undefined);
    localStorage.removeItem("currentChat");
    // localStorage.removeItem("videolink_chat");
    localStorage.setItem("messages", JSON.stringify([]));
    setCleanInput(!cleanInput);
  };

  const handleImages = () => {
    if (state.isPreviewImages) {
      // setIsLoadingImgView(true);
      const filtered = state.messages.filter(
        (message) =>
          message.type === "file" &&
          (message.content.urlContentType
            ? message.content.urlContentType.includes("image/")
            : message.content.name.split(".")[
                message.content.name.split.length - 1
              ] !== "pdf" &&
              !message.content.name
                .split(".")
                [message.content.name.split(".").length - 1].toLowerCase()
                .includes("mp4") &&
              !message.content.name
                .split(".")
                [message.content.name.split(".").length - 1].toLowerCase()
                .includes("ogg"))
      );
      filtered.map((filter, index) => (filter.index = index + 1));
      setMessagesWithImages(filtered);
      setSelectedImage(
        state.selectedImage
          ? filtered.find((image) => image._id === state.selectedImage._id)
          : filtered[0]
      );
      // setIsLoadingImgView(false);
    }
  };

  const createContact = async (data) => {
    try {
      if (state && state.currentChat && state.currentChat.contact) {
        const result = await service.updateContact(
          state.currentChat.contact._id,
          data
        );
        state.currentChat.contact = result.contact;
        const { currentChat, chatList } = service.updateContactInfo(
          state.currentChat,
          state.chatList,
          data
        );
        setChatList(chatList);
        setCurrentChat(currentChat);
        setConfigDialog({
          open: true,
          type: "update",
        });
        const contactsInfo = await service.getContactsInfo();
        if (contactsInfo?.contacts && currentChat?.contact) {
          const formattedData = contactsInfo.contacts
            .filter((el) => el._id !== currentChat.contact._id)
            .map((obj) => {
              for (const key in obj) {
                if (obj.hasOwnProperty(key) && obj[key] === "No especificado") {
                  obj[key] = "";
                }
              }
              return obj;
            });
          setContactsInfo(formattedData);
        }
        if (currentChat?.contact) {
          if (
            currentChat &&
            currentChat.contact &&
            currentChat.contact.phone === ""
          )
            currentChat.contact.phone = "No especificado";
          if (
            currentChat &&
            currentChat.contact &&
            currentChat.contact.firstname === ""
          )
            currentChat.contact.firstname = "No especificado";
          if (
            currentChat &&
            currentChat.contact &&
            currentChat.contact.lastname === ""
          )
            currentChat.contact.lastname = "No especificado";
          if (
            currentChat &&
            currentChat.contact &&
            currentChat.contact.email === ""
          )
            currentChat.contact.email = "No especificado";

          const contactsMath = await service.getContactsMatch(
            currentChat?.contact
          );
          setContactsMatch(contactsMath?.result);
        }
      }
    } catch (err) {
      console.log(err);
      if (err?.dataResponse?.msg === "contact al ready exist") {
        setSnackbar({
          open: true,
          message:
            "El correo electrónico o número de teléfono ya se encuentra asignado",
          severity: "error",
        });
      } else {
        setSnackbar({
          open: true,
          message: "La información no se pudo actualizar",
          severity: "error",
        });
      }
    }
  };

  const openMergeContact = async (data) => {
    setModeCloseDialog(false);
    handleDialogClose(true);
    setDataContact(data);
  };

  const mergeContact = async () => {
    setIsLoading(true);
    try {
      if (dataContact !== null) {
        const response = await service.mergeContact(dataContact);
        const chat = state.chatList.find(
          (chat) => chat.contact._id === dataContact.to
        );
        if (chat) {
          state.chatList = state.chatList.filter(
            (chat) => chat.contact._id !== dataContact.to
          );
          socket?.emit("chat_ended", {
            chat_id: chat._id,
            reason: {
              type: "success",
              reason: "Chat finalizado por mezcla de contacto",
            },
          });
        }

        state.currentChat.contact = response.contactUpdate;

        const { currentChat, chatList } = service.updateContactInfo(
          state.currentChat,
          state.chatList,
          dataContact
        );
        setChatList(chatList);
        setCurrentChat(currentChat);
        await init(false);
        setDataContact(null);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    init();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      if (currentChat) {
        try {
          const contactsInfo = await service.getContactsInfo();
          if (contactsInfo?.contacts && currentChat?.contact) {
            const formattedData = contactsInfo.contacts
              .filter((el) => el._id !== currentChat.contact._id)
              .map((obj) => {
                for (const key in obj) {
                  if (
                    obj.hasOwnProperty(key) &&
                    obj[key] === "No especificado"
                  ) {
                    obj[key] = "";
                  }
                }
                return obj;
              });
            setContactsInfo(formattedData);
          }
        } catch (err) {
          console.log(err);
        }
      }
    };

    fetchData();
  }, [currentChat]);

  useEffect(() => {
    function handleKeyup(e) {
      switch (e.keyCode) {
        case 27:
          setIsPreviewImages(false);
          break;
        case 37:
          document.getElementById("leftButton")
            ? document.getElementById("leftButton").click()
            : console.log("click");
          break;
        case 39:
          document.getElementById("rigthButton")
            ? document.getElementById("rigthButton").click()
            : console.log("click");
          break;
      }
    }
    document.addEventListener("keyup", (e) => handleKeyup(e));
    return () => document.removeEventListener("keyup", handleKeyup);
  }, []);

  useEffect(() => {
    handleImages();
  }, [state.isPreviewImages]);

  useEffect(() => {
    const find = chatList.find((item) => item.count > 0);

    if (find) service.handleNotifyFavicon();
    else service.handleFavicon();
  }, [chatList, currentChat]);

  return (
    <>
      {state.user ? (
        <ChatContextProvider>
          {state.isPreviewImages ? (
            <ImgView
              toolbarHeight={77}
              setSelectedImage={setSelectedImage}
              selectedImage={state.selectedImage}
              localMessages={state.messages}
              currentChat={state.currentChat}
              setIsImgView={setIsPreviewImages}
              filteredMessages={state.messagesWithImages}
              isLoadingImgView={false}
              isCountActive={false}
            />
          ) : isMobile ? (
            <MobileView
              state={state}
              container={container}
              handleSidebar={handleSidebar}
              onLogout={onLogout}
              handleDialogClose={handleDialogClose}
              setCurrentChat={setCurrentChat}
              socket={socket}
              setConfigDialog={setConfigDialog}
              onUpdateClientInfo={onUpdateClientInfo}
              onSendMessage={onSendMessage}
              onUploadImageMessage={onUploadImageMessage}
              connect={connect}
              getCurrentFiles={getCurrentFiles}
              getCurrentChat={getCurrentChat}
              handleAgentConnect={handleAgentConnect}
              setIsPreviewImages={setIsPreviewImages}
              setSelectedImage={setSelectedImage}
              navigate={navigate}
              cleanInput={cleanInput}
              height={height}
              onUpdateDynamicForm={onUpdateDynamicForm}
              isLoading={isLoading}
              loading={loading}
              setIsLoading={setIsLoading}
              width={width}
              createContact={createContact}
              getMoreMessages={getMoreMessages}
              loadingMoreMessages={loadingMoreMessages}
              contactsInfo={contactsInfo}
              mergeContact={openMergeContact}
              getContactsInfo={getContactsInfo}
            />
          ) : (
            <DesktopView
              state={state}
              container={container}
              handleSidebar={handleSidebar}
              onLogout={onLogout}
              handleDialogClose={handleDialogClose}
              setCurrentChat={setCurrentChat}
              socket={socket}
              setConfigDialog={setConfigDialog}
              onUpdateClientInfo={onUpdateClientInfo}
              onSendMessage={onSendMessage}
              onUploadImageMessage={onUploadImageMessage}
              connect={connect}
              getCurrentFiles={getCurrentFiles}
              getCurrentChat={getCurrentChat}
              handleAgentConnect={handleAgentConnect}
              setIsPreviewImages={setIsPreviewImages}
              setSelectedImage={setSelectedImage}
              navigate={navigate}
              cleanInput={cleanInput}
              height={height}
              onUpdateDynamicForm={onUpdateDynamicForm}
              isLoading={isLoading}
              loading={loading}
              setIsLoading={setIsLoading}
              width={width}
              createContact={createContact}
              getMoreMessages={getMoreMessages}
              loadingMoreMessages={loadingMoreMessages}
              contactsInfo={contactsInfo}
              mergeContact={openMergeContact}
              getContactsInfo={getContactsInfo}
              contactsMatch={contactsMatch}
            />
          )}
          <DialogClose
            open={state.isOpenDialog}
            setOpen={handleDialogClose}
            onClose={() => {
              handleDialogClose(false);
              if (!modeCloseDialog) setModeCloseDialog(true);
              setDataContact(null);
            }}
            onAccept={modeCloseDialog ? onLogout : mergeContact}
            isClose={modeCloseDialog}
          />
          <DialogTransferAtention
            open={state.configDialog.open}
            type={state.configDialog.type}
            setOpen={setConfigDialog}
            abilities={state.abilities}
            groups={state.groups}
            onTransferCall={onTransferCall}
            getCurrentAbilities={getCurrentAbilities}
            currentAbilities={state.currentAbilities}
            title={service.getTitle(state)}
            onChatEnd={onChatEnd}
            isMobile={isMobile}
            height={height}
          />
          <Snackbar
            autoHideDuration={3000}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={state.snackbar.open}
            onClose={() =>
              setSnackbar({
                open: false,
                message: undefined,
                severity: undefined,
              })
            }
            style={{ display: state.snackbar.open ? "flex" : "none" }}
          >
            <Alert severity={state.snackbar.severity}>
              {state.snackbar.message}
            </Alert>
          </Snackbar>
        </ChatContextProvider>
      ) : (
        <Navigate to="/" />
      )}
    </>
  );
};

export default Chat;
